import styled from 'styled-components';

import { Box } from '@yoweb/ui/components/Box';
import { Link } from '@yoweb/ui/components/Link';
import {
  Text,
  type TextProps,
  Label,
  type LabelProps,
  Title,
} from '@yoweb/ui/components/typography';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import type { BoxKeysProps } from '@yoweb/ui/components/Box';

export const Body = ({
  size = 'xl',
  variant = 'muted',
  children,
  ...textProps
}: TextProps & WithChildren) => (
  <BodyText size={size} variant={variant} {...textProps}>
    {children}
  </BodyText>
);

export const BodyHeading = ({ size = 'sm', children, ...titleProps }: TextProps & WithChildren) => (
  <BodyHeadingText size={size} as="h3" {...titleProps}>
    {children}
  </BodyHeadingText>
);

export const BodyLabel = ({
  size = 'sm',
  variant = 'muted',
  children,
  ...textProps
}: LabelProps & Partial<WithChildren>) => (
  <Label size={size} variant={variant} as="span" {...textProps}>
    {children}
  </Label>
);

export const BodyLink = ({
  size = 'xl',
  variant = 'muted',
  textDecoration = 'underline',
  shouldOpenInTab = false,
  href,
  children,
  ...textProps
}: TextProps &
  Partial<WithChildren> & {
    href: string;
    shouldOpenInTab?: boolean;
  }) => (
  <Link href={href} shouldOpenInTab={shouldOpenInTab}>
    <Text size={size} variant={variant} as="span" textDecoration={textDecoration} {...textProps}>
      {children}
    </Text>
  </Link>
);

export const BodyHeader = ({
  label,
  title,
  mb = 0,
  mt = 0,
}: { title: string | ReactNode; label: string } & Pick<BoxKeysProps, 'mb' | 'mt'>) => (
  <BodyHeaderGrid mb={mb} mt={mt}>
    <Label size={{ _: 'sm', lg: 'md' }}>{label}</Label>
    {typeof title === 'string' ? (
      <StyledTitle size={{ _: 'md', md: 'lg', lg: 'xl' }} whiteSpace="pre-wrap">
        {title}
      </StyledTitle>
    ) : (
      <div>{title}</div>
    )}
  </BodyHeaderGrid>
);

const BodyText = styled(Text)`
  && {
    margin-top: ${getSpace('normal2')};
  }
`;

const BodyHeadingText = styled(Title)`
  && {
    margin-top: ${getSpace('normal2')};
  }
`;

const StyledTitle = styled(Title)`
  && {
    white-space: pre-line;
  }
`;

const BodyHeaderGrid = styled(Box)`
  display: grid;
  grid-gap: ${pxToRem(10)};
  text-align: center;

  ${media.md`
    grid-gap: ${pxToRem(18)};
  `}

  ${media.lg`
    grid-gap: ${pxToRem(14)};
  `}
`;
