import styled from 'styled-components';
import type { FontSizes } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import { textBase, type TextBaseProps } from './textBase';

type TextSpanSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type TextSpanSizeProps = WithSize<TextSpanSize>;

export type TextSpanProps = TextBaseProps & TextSpanSizeProps;

const sizeMapping: Record<TextSpanSize, keyof FontSizes> = {
  xs: 'bodyXs',
  sm: 'bodySm',
  md: 'bodyMd',
  lg: 'bodyLg',
  xl: 'bodyXl',
};

/**
 * font-size + line-height + letter-spacing mixin.
 * Control all with a single prop.
 */
export const textSpanSizeBodyMixin = createSizeMixin<TextSpanSize, TextSpanProps>(
  ({ size = 'lg' as TextSpanSize }, tagFn) =>
    tagFn`
    font-size: ${th.getFontSize(sizeMapping[size])};
    line-height: ${th.getLineHeight(sizeMapping[size])};
    letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
  `,
);

/**
 * Body text component.
 */
export const TextSpan = styled.span<TextSpanProps>`
  ${textBase};
  ${textSpanSizeBodyMixin};
`;

TextSpan.defaultProps = {
  size: 'lg',
};
