import { Element, type JSXFunctionSerializer } from '@prismicio/react';
import styled from 'styled-components';
import { Fragment } from 'react';

import {
  getSpace,
  getFontSize,
  getColor,
  getFontWeight,
  getLineHeight,
} from '@yoweb/ui/styles/utils/theme';
import { Link } from '@yoweb/ui/components/Link';
import { Text, TextSpan, Title } from '@yoweb/ui/components/typography';
import { BodyLink, Body } from '@yoweb/ui/components/marketing/MarketingTexts';
import { media } from '@yoweb/ui/styles/utils';

const Ul = styled.ul<{ $isEmbedded?: boolean }>`
  margin-left: ${getSpace('normal4')};
  list-style: disc;
  margin-bottom: ${getSpace('normal1')};
  color: ${({ $isEmbedded }) => ($isEmbedded ? getColor('base900') : getColor('base700'))};

  li {
    margin-top: ${getSpace('normal1')};
    font-size: ${({ $isEmbedded }) =>
      $isEmbedded ? getFontSize('bodyLg') : getFontSize('bodyXl')};
  }
`;

const Ol = styled.ol<{ $isEmbedded?: boolean }>`
  margin-left: ${getSpace('normal4')};
  list-style: numeric;
  color: ${({ $isEmbedded }) => ($isEmbedded ? getColor('base900') : getColor('base700'))};
  margin-bottom: ${getSpace('normal1')};

  li {
    margin-top: ${getSpace('normal1')};
    font-size: ${({ $isEmbedded }) =>
      $isEmbedded ? getFontSize('bodyLg') : getFontSize('bodyXl')};
  }
`;

const Em = styled.em`
  font-style: italic;
`;

const Strong = styled.strong`
  font-weight: ${getFontWeight('bold')};
`;

const DarkLink = styled(Link)`
  color: ${getColor('base900')};
`;

const TitleInfo = styled(Title)`
  && {
    white-space: pre-line;
  }
`;

const StyledCTASubtitleText = styled(Text)`
  && {
    margin-bottom: ${getSpace('normal3')};

    & > br {
      display: block;
      content: '';
      margin-top: ${getSpace('small4')};
    }
  }
`;

/**
 * Determine how RichText from Prismic gets rendered.
 */
export const defaultSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text variant="muted" size={{ _: 'lg', lg: 'xl' }} key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );
    case Element.heading1:
      return (
        <TitleInfo size={{ _: 'sm', lg: 'md' }} data-testid="corner-card-section-title">
          {children}
        </TitleInfo>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;
    case Element.list:
      return <Ul key={_key}>{children}</Ul>;

    case Element.oList:
      return <Ol key={_key}>{children}</Ol>;

    case Element.em:
      return <Em key={_key}>{children}</Em>;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

/**
 * Determine how inverse variant label field RichText gets rendered.
 * Docs: https://prismic.io/docs/technologies/html-serializer-reactjs
 */
export const inverseLabelSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text variant="inverse" as="span" key={_key} size={{ _: 'sm', md: 'lg', lg: 'xl' }}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );

    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    case Element.strong:
      return (
        <Text
          key={_key}
          weight="bold"
          variant="inverse"
          as="span"
          size={{ _: 'sm', md: 'lg', lg: 'xl' }}
        >
          {children}
        </Text>
      );

    default:
      return null;
  }
};

/**
 * Determine how variant label field RichText gets rendered.
 */
export const labelSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text key={_key} size={{ _: 'md', md: 'sm', lg: 'xl' }}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );

    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    case Element.strong:
      return (
        <Text key={_key} weight="bold" as="span" size={{ _: 'md', md: 'sm', lg: 'xl' }}>
          {children}
        </Text>
      );

    default:
      return null;
  }
};

/**
 * Determine how smallprint text field RichText gets rendered.
 */
export const smallPrintSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return <Fragment key={_key}>{node.text.length ? children : <span>&nbsp;</span>}</Fragment>;
    case Element.hyperlink:
      return node?.data?.url ? (
        <DarkLink href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </DarkLink>
      ) : null;
    default:
      return null;
  }
};

const WaitlistUl = styled.ul`
  margin-left: ${getSpace('normal2')};
  list-style: disc;
  margin-bottom: ${getSpace('normal1')};

  li {
    margin-top: ${getSpace('small2')};
    font-size: ${getFontSize('bodyLg')};
    line-height: ${getLineHeight('bodyLg')};
    letter-spacing: 0.01em;
  }
`;

export const waitlistSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text size={{ _: 'lg', lg: 'xl' }} key={_key}>
          {node.text.length ? children : null}
        </Text>
      );

    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;
    case Element.list:
      return <WaitlistUl key={_key}>{children}</WaitlistUl>;

    case Element.oList:
      return <Ol key={_key}>{children}</Ol>;

    case Element.em:
      return <Em key={_key}>{children}</Em>;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const subtitleSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Title as="p" weight="regular" size={{ _: 'xs', lg: 'sm' }} key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Title>
      );
    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    default:
      return null;
  }
};

export const paragraphBodyLinkSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Body key={_key} size={{ _: 'lg', md: 'xl' }}>
          {children}
        </Body>
      );
    case Element.heading1:
    case Element.heading2:
    case Element.heading3:
    case Element.heading4:
    case Element.heading5:
    case Element.heading6:
      return (
        <Title key={_key} size={{ _: 'md', md: 'lg', lg: 'xl' }} whiteSpace="pre-wrap">
          {children}
        </Title>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <BodyLink href={node?.data?.url} key={_key} size={{ _: 'lg', md: 'xl' }}>
          {children}
        </BodyLink>
      ) : null;

    default:
      return null;
  }
};

const TextInfo = styled(Text)`
  && {
    margin-bottom: ${getSpace('normal2')};

    ${media.lg`
      margin-bottom: ${getSpace('normal3')};
    `}
  }
`;

export const bulletpointSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <TextInfo size={{ _: 'lg', md: 'md', lg: 'lg' }} key={_key}>
          {node.text.length ? children : null}
        </TextInfo>
      );
    case Element.heading1:
      return (
        <TitleInfo size={{ _: 'sm', lg: 'md' }} data-testid="corner-card-section-title">
          {children}
        </TitleInfo>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;
    case Element.list:
      return <WaitlistUl key={_key}>{children}</WaitlistUl>;

    case Element.oList:
      return <Ol key={_key}>{children}</Ol>;

    case Element.em:
      return <Em key={_key}>{children}</Em>;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const inverseBulletpointSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <TextInfo size={{ _: 'lg', md: 'md', lg: 'lg' }} key={_key} variant="inverse">
          {node.text.length ? children : null}
        </TextInfo>
      );
    case Element.heading1:
      return (
        <TitleInfo
          size={{ _: 'sm', lg: 'md' }}
          data-testid="corner-card-section-title"
          variant="inverse"
        >
          {children}
        </TitleInfo>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;
    case Element.list:
      return <WaitlistUl key={_key}>{children}</WaitlistUl>;

    case Element.oList:
      return <Ol key={_key}>{children}</Ol>;

    case Element.em:
      return <Em key={_key}>{children}</Em>;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const coloredCtaSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Title
          size={{ _: 'xs', lg: 'sm' }}
          as="p"
          weight="regular"
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          {node.text.length ? children : null}
        </Title>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const coloredCtaDisclaimerSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text size="lg" key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <DarkLink href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </DarkLink>
      ) : null;
    default:
      return null;
  }
};

export const disclaimerSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text size="sm" variant="muted" key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <DarkLink href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </DarkLink>
      ) : null;
    default:
      return null;
  }
};

export const largeTextSerializer: JSXFunctionSerializer = (_type, node, _text, children, _key) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <TextSpan size="xl" variant="muted">
          {node.text.length ? children : null}
        </TextSpan>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const dreamGallerySerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Text variant="primary" size={{ _: 'xl' }} key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Text>
      );
    case Element.heading1:
      return (
        <Title
          textAlign="center"
          size={{ _: 'md', md: 'lg' }}
          maxWidth={664}
          whiteSpace="pre-wrap"
          key={_key}
        >
          {children}
        </Title>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const mediaHeaderSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <Title as="p" size={{ _: 'xs', md: 'sm' }} weight="regular" key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </Title>
      );
    case Element.heading1:
      return (
        <Title as="h1" size={{ _: 'lg', md: 'xl', lg: 'xxl' }} whiteSpace="pre-wrap" key={_key}>
          {children}
        </Title>
      );
    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    default:
      return null;
  }
};

export const ctaSubtitleSerializer: JSXFunctionSerializer = (
  _type,
  node,
  _text,
  children,
  _key,
) => {
  switch (node.type) {
    case Element.paragraph:
      return (
        <StyledCTASubtitleText size={{ _: 'lg', lg: 'xl' }} textAlign="center" key={_key}>
          {node.text.length ? children : <span>&nbsp;</span>}
        </StyledCTASubtitleText>
      );
    case Element.strong:
      return <Strong key={_key}>{children}</Strong>;

    case Element.hyperlink:
      return node?.data?.url ? (
        <Link href={node?.data?.url} key={_key} hasUnderline shouldOpenInTab>
          {children}
        </Link>
      ) : null;

    default:
      return null;
  }
};
